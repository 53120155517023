body {
	margin: 0;
	font-family: 'lato';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	display: flex;
	background-color: #e7e7e7;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

div#root {
	flex: 1;
	display: flex;
	position: relative;
	flex-direction: column;
}